import React from "react";
import { Box, Container, Typography, Grid, Tabs, Tab } from "@mui/material";
import Image from "next/image";
import { TypingEffectText } from "../foundations";
import { RenderBulletedHeadlineList } from "blocks/atoms";
import { isMobile } from "react-device-detect";
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";

const videoCallFeature = {
  title: "Built-in videocalls for hasslefree meetings",
  imageSrc: "/static/images/FEATURE_PANEL.jpeg",
  description: [
    "Predictable meeting spaces. No Links, No hassles",
    "Premium quality conferencing (HD-Grade)",
    "Easy Video recordings with diarized transcripts",
    "Ideate together on shared whiteboards",
    "Highlight important segments with meeting markers",
    "Your virtual AI assistant to auto-generate minutes and actions",
    "Get insightful meeting analytics & attendance reports",
  ],
};
const DocumentsFeature = {
  title: "Secure Datarooms for Document Sharing",
  imageSrc: "/static/images/DataroomScreenshot.png",
  description: [
    "Easy-to-use reliable, private and secure document rooms",
    "Store, Share and collaborate",
    "Rich document engagement analytics",
    "Watermark protections",
    "Integrated file viewer",
  ],
};

const CollaborateFeature = {
  title: "Work together seamlessly",
  imageSrc: "/static/images/CollaborateScreenshot.png",
  description: [
    "Keep on top of your and team actions, easily assign and watch task progress",
    "Bring your ideas to life with shared whiteboard spaces",
    "Conduct polls and surveys to get realtime feedback",
    "Schedule and plan meetings effortlessly",
    "Smart notifications to engage your team members and keep them informed of progress",
  ],
};

const ChatsFeature = {
  title: "Boundaryless Conversations",
  imageSrc: "/static/images/ChatScreenshot.png",
  description: [
    "Easy to setup chat spaces and breakout discussion rooms",
    "Comunication at your convenience. Persistent conversation spaces for closed working groups",
    "Unified view of all your conversations - 1:1, Suites, breakouts, meetings etc",
    "Better control over chat access and on shared assets",
    "Optional disappearing messages feature",
  ],
};
const sentencesArray = ["Video Meet", "Share Files", "Chat", "Collaborate"];

export default function FeaturesPanel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const increment = React.useRef(null);

  const delayInterval = 20000;

  const handleChange = (event, newValue) => {
    setActiveIndex(newValue);
    clearInterval(increment.current);
  };

  const handleIncrement = () => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === 3 ? 0 : prevActiveIndex + 1
    );
  };

  const getRenderFeature = (index) => {
    switch (index) {
      case 0:
        return videoCallFeature;
      case 1:
        return DocumentsFeature;
      case 2:
        return ChatsFeature;
      case 3:
        return CollaborateFeature;
      default:
        return videoCallFeature;
    }
  };
  return (
    <Container
      maxWidth="lg"
      sx={{
        position: "relative",
        textAlign: "center",

        py: 7,
        px: 3,
        display: "flex",
        flexDirection: "column",

        minHeight: "80vh",
        backgroundColor: (theme) => theme.palette.background.paper,
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={isMobile ? "h3" : "h1"}
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "inline",
              px: 1,
              py: 0,
              background: (theme) => theme.palette.secondary.light,

              boxShadow: (theme) =>
                `.2em 0 0 ${theme.palette.secondary.light}, -.2em 0 0 ${theme.palette.secondary.light}`,
            }}
          >
            <TypingEffectText
              typingDelay={100}
              delayInterval={delayInterval}
              onTypingDone={handleIncrement}
              text={sentencesArray[activeIndex]}
              loop={false}
            />
          </Box>
          <span style={{ marginLeft: 10 }}>On Trryst</span>
        </Typography>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          sx={{ mt: 4, mb: { xs: 4, md: 7 } }}
        >
          Very easy-to-use Hybrid work platform. No App needed
        </Typography>
        <StyledRoundedTabs
          value={activeIndex}
          onChange={handleChange}
          selectionFollowsFocus
          sx={{ mt: 5, maxWidth: 800 }}
        >
          <StyledRoundedTabList sx={{ backgroundColor: "background.paper" }}>
            <StyledRoundedTab
              textColor="secondary"
              value={0}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ pr: 2, display: { xs: "none", sm: "block" } }}>
                <Image
                  alt={"Videocall"}
                  height={30}
                  width={30}
                  src={
                    activeIndex === 0
                      ? "/static/customicons/video-call-basic-secondary.svg"
                      : "/static/customicons/video-call-basic-primary.svg"
                  }
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ display: { xs: "block", sm: "block" } }}
              >
                {isMobile ? "Video" : "Video Call"}
              </Typography>
            </StyledRoundedTab>

            <StyledRoundedTab
              value={1}
              textColor="secondary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ pr: 2, display: { xs: "none", sm: "block" } }}>
                <Image
                  alt="Files"
                  height={30}
                  width={30}
                  src={
                    activeIndex === 1
                      ? "/static/customicons/document-basic-secondary.svg"
                      : "/static/customicons/document-basic-primary.svg"
                  }
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>

              <Typography
                variant="subtitle1"
                sx={{ display: { xs: "block", sm: "block" } }}
              >
                {isMobile ? "Docs" : "Documents"}
              </Typography>
            </StyledRoundedTab>
            <StyledRoundedTab
              value={2}
              textColor="secondary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ pr: 2, display: { xs: "none", sm: "block" } }}>
                <Image
                  alt="chat"
                  height={30}
                  width={30}
                  src={
                    activeIndex === 2
                      ? "/static/customicons/chat-basic-secondary.svg"
                      : "/static/customicons/chat-basic-primary.svg"
                  }
                  style={{
                    width: 40,
                    paddingRight: 5,
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>

              <Typography
                variant="subtitle1"
                sx={{ display: { xs: "block", sm: "block" } }}
              >
                Chat
              </Typography>
            </StyledRoundedTab>
            <StyledRoundedTab
              value={3}
              textColor="secondary"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ pr: 2, display: { xs: "none", sm: "block" } }}>
                <Image
                  alt="Collaborate"
                  height={30}
                  width={30}
                  src={
                    activeIndex === 3
                      ? "/static/customicons/collaborate-basic-secondary.svg"
                      : "/static/customicons/collaborate-basic-primary.svg"
                  }
                  style={{
                    width: 40,
                    paddingRight: 5,
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>

              <Typography
                variant="subtitle1"
                sx={{ display: { xs: "block", sm: "block" } }}
              >
                Collaborate
              </Typography>
            </StyledRoundedTab>
          </StyledRoundedTabList>
        </StyledRoundedTabs>

        <Box sx={{ mt: 3 }}>
          <Grid
            container
            spacing={2}
            direction={{ xs: "column-reverse", md: "row" }}
          >
            <Grid item xs={12} md={6}>
              <RenderBulletedHeadlineList
                title={getRenderFeature(activeIndex).title}
                listItems={getRenderFeature(activeIndex).description}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                minHeight: 200,
              }}
            >
              <Image
                alt={getRenderFeature(activeIndex).title}
                src={getRenderFeature(activeIndex).imageSrc}
                fill
                sizes="50vw"
                style={{
                  objectFit: "contain",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
