import React from "react";
import { Typography, Box, Grid, Container, styled, Stack } from "@mui/material";
import { HeadlineUnderlineEffectText, HeroSnippet } from "../foundations";
import { RenderBulletedHeadlineList, SignupButton } from "blocks/atoms";
import Image from "next/image";
import { motion } from "framer-motion";
import {
  MotionContainer,
  varWrapEnter,
  varFade,
} from "blocks/atoms/uieffects/animate";
import { useSettingsContext } from "providers/SettingsProvider";

import TrrystDataSuiteBanner from "public/static/images/TrrystDataSuiteBanner.svg";
//import ReactStars from "react-rating-stars-component";

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: "relative",
  // backgroundColor: theme.palette.grey[400],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: 10,
  paddingTop: 20,
  backgroundColor: "white",
  [theme.breakpoints.up("md")]: {
    paddingLeft: 25,
    paddingTop: 70,

    width: "100%",
    minHeight: "calc(100vh - 120px)",
    top: 0,
    left: 0,
    position: "fixed",
  },
}));

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const HeroImgStyle = styled("video")(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: "100%",
  margin: "auto",
  position: "absolute",
  filter: `drop-shadow(40px 80px 80px rgba(0, 0, 0, 0.8))`,
  [theme.breakpoints.up("lg")]: {
    right: "8%",
    width: "auto",
    height: "48vh",
  },
}));

export default function HeroPanel() {
  const { scrollY } = useSettingsContext();
  const [windowHeight, setWindowHeight] = React.useState(0);
  const HeadlineInfo = {
    title: "Bring it together on Trryst",
    description: [
      "Converse securely in purpose-built rooms. Made easy for cross-boundary teams.",
      "Only unified platform offering videocalls + documents + collaboration. AI powered decision insights for faster outcomes",
      "No two conversations are alike. Customizable templates for specific use-cases",
    ],
  };
  const HeadlineSnippets = [
    {
      title: "Unified Platform ",
      subtitle:
        "Conduct Videocalls, Chat, Share files and collaborate securely",
      imageSrc: "/static/customicons/Point1.svg",
    },
    {
      title: "Enhanced with AI ",
      subtitle: "AI-based insights powers better business outcomes",
      imageSrc: "/static/customicons/Point2.svg",
    },
    {
      title: "Simplified for Hybrid teams",
      subtitle: "Cross-boundary communications made simple and easy-to-use",
      imageSrc: "/static/customicons/Point3.svg",
    },
    {
      title: "Tailored to Suit",
      subtitle: "Purpose-built - Boardroom Suite, Virtual Datarooms etc",
      imageSrc: "/static/customicons/Point4.svg",
    },
  ];
  React.useEffect(() => setWindowHeight(window.innerHeight), []);
  return (
    <MotionContainer>
      <RootStyle
        initial="initial"
        animate="animate"
        variants={varWrapEnter}
        style={{
          position: scrollY < windowHeight + 180 ? "fixed" : "relative",
        }}
      >
        {/* <HeroOverlayStyle
          alt="overlay"
          src="/static/overlay.svg"
          variants={varFade().in}
        /> */}
        {/* <HeroImgStyle
          alt="hero"
          src="/static/home/hero.png"
          variants={varFade().inUp}
        /> */}
        <Container
          maxWidth="lg"
          sx={{
            p: 2,

            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 320px)",
            mt: { xs: 6, md: 0 },
            justifyContent: "center",
          }}
        >
          <Grid container spacing={5}>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: { xs: 3, sm: 4, md: 7 },
                  mx: { xs: "auto", md: 0 },
                }}
              >
                <HeadlineUnderlineEffectText
                  text={"Unfragment Your Conversations"}
                />
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      md: "none",
                    },
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: { xs: "center", md: "flex-start" },

                    height: "30vh",
                    position: "relative",
                  }}
                >
                  <Image
                    src={TrrystDataSuiteBanner}
                    alt={"Trryst for Datarooms"}
                    priority={true}
                    fill
                    sizes={"30vw"}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <motion.div variants={varFade().in}>
                  {/* <RenderBulletedHeadlineList
                    title={HeadlineInfo.title}
                    listItems={HeadlineInfo.description}
                  /> */}
                  <Typography variant="h3" sx={{ fontWeight: 700 }}>
                    {HeadlineInfo.title}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    sx={{ my: 3 }}
                    spacing={3}
                    alignItems={"stretch"}
                  >
                    {HeadlineSnippets.map((item, index) => (
                      <Grid key={index} item xs={6}>
                        <HeroSnippet
                          key={index}
                          title={item.title}
                          subtitle={item.subtitle}
                          imageSrc={item.imageSrc}
                          sx={{
                            minWidth: { xs: 100, md: 200 },
                            height: "100%",
                            minHeight: 50,
                            zIndex: 5,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </motion.div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 3,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <motion.div variants={varFade().inRight}>
                  <SignupButton />
                </motion.div>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, fontStyle: "italic", fontWeight: 500 }}
                >
                  * Signup Today and get a FREE Personal Suite
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                position: "relative",
                display: {
                  xs: "none",
                  md: "flex",
                },
                flexDirection: "column",
                justifyContent: "center",
                p: 3,
              }}
            >
              <Image
                src={TrrystDataSuiteBanner}
                alt={"Trryst for Datarooms"}
                priority={true}
                fill
                sizes={"30vw"}
                style={{
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            spacing={3}
            direction={{
              mt: { xs: 3, sm: 4, md: 7 },
              xs: "column-reverse",
              md: "row",
            }}
          >
            <Grid item xs={12} md={6}> */}
          {/* <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
            <ReactStars
              count={5}
              value={4.8}
              size={24}
              isHalf={true}
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
              activeColor="#ffd700"
              edit={false}
              disabled
            />
            <Typography variant="caption" sx={{ mt: 0 }}>
              * Based on feedback by users on G2
            </Typography>
          </Box> */}
          {/* </Grid>
          </Grid> */}
        </Container>
      </RootStyle>
      {scrollY < windowHeight + 180 && (
        <Box sx={{ height: { xs: "calc(100vh - 180px)" } }} />
      )}
    </MotionContainer>
  );
}

{
  /*<Stack
              direction="row"
              spacing={1.5}
              justifyContent={{ xs: "center", md: "flex-start" }}
            >
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_sketch.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_figma.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_material.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_react.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_js.svg"
              />
              <motion.img
                variants={varFade().inRight}
                src="/static/home/ic_m_ts.svg"
              />
            </Stack>*/
}
