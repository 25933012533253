// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
export { default as PrivacyPolicy } from "./PrivacyPolicy";
export { default as TermsAndConditions } from "./TermsAndConditions";
export { default as LandingFooter } from "blocks/views/Layouts/LandingPageLayout/LandingFooter";
export { default as AboutUs } from "./AboutUs";
export { default as PricingModels } from "./PricingModels";

export { default as HeroPanel } from "./HeroPanel";
export { default as ProofPoints } from "./ProofPoints";
export { default as FeaturesPanel } from "./FeaturesPanel";
export { default as HighlightsPanel } from "./HighlightsPanel";
export { default as SuiteIllustrationsPanel } from "./SuiteIllustrationsPanel";
export { default as CTAPanel } from "./CTAPanel";
export { default as ShowLandingVideo } from "./ShowLandingVideo";
