// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { styled } from "@mui/material/styles";
import {
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
//
import { varFade, MotionInView } from "blocks/atoms/uieffects/animate";
import { SignupButton } from "blocks/atoms";

import Rocketman from "blocks/atoms/features/Rocketman";
// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  overflow: "hidden",
  paddingBottom: theme.spacing(10),

  // borderRadius: theme.shape.borderRadiusMd,

  [theme.breakpoints.up("md")]: {
    display: "flex",
    maxWidth: "100%",
    paddingBottom: 0,
    alignItems: "center",
  },
}));

// ----------------------------------------------------------------------

export default function CTAPanel() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ mt: 3, bgcolor: "primary.main" }}
    >
      <ContentStyle>
        <Grid
          container
          spacing={0}
          direction={{ xs: "column-reverse", md: "row" }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pt: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant={isMobile ? "h5" : "h2"}
                sx={{ color: "primary.contrastText" }}
              >
                Get started with Trryst
              </Typography>
              <Typography
                variant={isMobile ? "subtitle1" : "h6"}
                sx={{ color: "primary.contrastText", my: 2 }}
              >
                Conduct your conversations over 40% easier
              </Typography>
              <Stack direction="row" spacing={3} sx={{ my: 2 }}>
                <SignupButton
                  variant="contained"
                  size={isMobile ? "medium" : "large"}
                  sx={{
                    bgcolor: "common.white",
                    color: "common.black",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    boxShadow: (theme) => theme.customShadows.z8,
                    "&:hover": {
                      bgcolor: "common.grey",
                      color: "common.white",
                    },
                  }}
                />
                <Button
                  variant="outlined"
                  size={isMobile ? "medium" : "large"}
                  sx={{ border: "1px solid white", color: "common.white" }}
                  href={"/home/contactus"}
                >
                  Request Demo
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/*<Box component="img" alt="rocket" src="/static/home/rocket.png" sx={{ maxWidth: 460, width: 1 }} />*/}
            {/*<Box sx={{ maxWidth: 460, background: "transparent" }}>
                 <Rocketman />
              </Box>*/}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                flexGrow: 1,

                backgroundImage: 'url("/static/images/CTABackground.png")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "35vh",
                // boxShadow: (theme) =>
                //   `0 0 12px 12px ${theme.palette.common.black} inset`,
              }}
            />
          </Grid>
        </Grid>
      </ContentStyle>
    </Container>
  );
}
