import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  darken,
  alpha,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { RenderBulletedHeadlineList } from "blocks/atoms";

export default function SuiteIllustrationsAccordion({
  activeIndex,
  handleChange,
  description = {},
  suiteType,
  backgroundPicture,
}) {
  return [
    "Boardroom Suites",
    "Executive Suites",
    "Virtual Dataroom Suites",
    "Sales Suites",
  ].map((descTitle, index) => {
    return (
      <Accordion
        key={index}
        expanded={activeIndex === index}
        onChange={() => handleChange(index)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h5" color="text.secondary">
            {descTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 3,
              pl: 5,
              height: "100%",
              backgroundColor: (theme) => theme.palette[suiteType].main,
              backgroundImage: (theme) =>
                `linear-gradient(135deg, ${darken(
                  theme.palette[suiteType].main,
                  0.9
                )}, ${alpha(
                  theme.palette[suiteType].main,
                  0.3
                )}), url(${backgroundPicture})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <RenderBulletedHeadlineList
              title={description.title}
              listItems={description.description}
              ListItemProps={{ sx: { color: "primary.contrastText" } }}
              ListSubheaderTypographyProps={{ color: "primary.contrastText" }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  });
}
