// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Container, Typography, Box } from "@mui/material";
//
import { FlowBox } from "blocks/atoms/uistyles";
// components
import Page from "blocks/views/Layouts/Page";
//
import PerfectScrollbar from "react-perfect-scrollbar";
import { ConsolidatedPricing } from "blocks/views/LandingPage/foundations";
import { ComparePricingTiers } from "blocks/modules/Subscriptions/foundations";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  height: "100%",
  flexGrow: 1,
  overflow: "hidden",
}));

export default function PricingModels() {
  return (
    <RootStyle title="Pricing | Trryst">
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flexGrow: 1,
        }}
      >
        <Typography variant="h1" align="center" paragraph sx={{ mt: 5 }}>
          Turbocharge your Stakeholder communications
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{ color: "text.primary", mb: 3 }}
        >
          We offer a variety of suites and subscription plans to make your
          meetings better.
        </Typography>
        <FlowBox sx={{ overflow: "hidden" }}>
          <PerfectScrollbar
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            <ConsolidatedPricing />
          </PerfectScrollbar>
        </FlowBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 3,
          }}
        >
          <ComparePricingTiers
            LabelComponent={
              <Typography variant="button">Compare Tiers</Typography>
            }
          />
        </Box>
      </Container>
    </RootStyle>
  );
}
