import React from "react";
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { alpha, styled, darken } from "@mui/material/styles";
import { RenderBulletedHeadlineList } from "blocks/atoms";
import { isMobile } from "react-device-detect";
import SuiteIllustrationsAccordion from "./SuiteIllustrationsAccordion";
import { StyledVerticalTabs, StyledVerticalTab } from "blocks/atoms/uistyles";

const getBackgroundImage = (suiteType) => {
  switch (suiteType) {
    case "boardSuite":
      return "/static/images/BoardroomBackground.png";
    case "execSuite":
      return "/static/images/ExecSuiteBackground.png";
    case "dataSuite":
      return "/static/images/DataSuiteBackground.png";
    case "salesSuite":
      return "/static/images/SalesSuiteBackground.png";
  }
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "suiteType",
})(({ theme, suiteType }) => {
  let backgroundColor = theme.palette[suiteType].main;
  let backgroundPicture = getBackgroundImage(suiteType);
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    height: "100%",
    backgroundColor: backgroundColor,
    backgroundImage: `linear-gradient(135deg, ${darken(
      backgroundColor,
      0.9
    )}, ${alpha(backgroundColor, 0.3)}), url(${backgroundPicture})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
});

const descriptions = {
  boardSuite: {
    suiteType: "boardSuite",
    title: "Turbocharge Your Boardroom Conversations",
    description: [
      "Intuitive technology to empower your leaders",
      "Bring your ideas to life with shared whiteboard spaces",
      "Actionable intelligence and decision insights for your board to make smarter decisions",
      "Simplified meeting administration to prepare, conduct, and actionize board meetings",
    ],
  },
  dataSuite: {
    suiteType: "dataSuite",
    title: "Focus on Your Deals in Virtual datarooms",
    description: [
      "Don’t be satisfied with VDR’s that are just basic file storage and sharing. Upgrade to an integrated deal platform with Trryst.",
      "File storage and protection, videocalls, chat, analytics,  and document sharing all (smart suite)",
      "Focus more on your deal - whether it is an M&A, RFP, Due Diligence  or Fundraising. Leverage Trryst to eliminate the operational complexity. ",
    ],
  },
  salesSuite: {
    suiteType: "salesSuite",
    title: "Suites built for Customer Engagement (Coming soon...)",
    description: [
      "Ever wanted a Communications suite with a CRM embedded? Trryst is the first of its kind.",
      "Customer Comunications at your convenience. Predictable & Persistent conversation spaces.",
      "Customized to your business with automations helping customer engagement",
      "All Documents available in one place. No more searching for files when dealing with clients.",
    ],
  },
  execSuite: {
    suiteType: "execSuite",
    title: "Increased Team Productivity with Integrated Conversations",
    description: [
      "Move faster by threading your conversations into organized spaces.",
      "Create a Virtual Office to enable Hybrid work spaces for your team",
      "Breakout spaces for fine-grained conversations and secure document exchange",
    ],
  },
};

export default function SuiteIllustrationsPanel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const increment = React.useRef(null);

  const delayInterval = 20000;

  const handleAccordionChange = (newValue) => {
    if (newValue === activeIndex) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(newValue);
    }

    clearInterval(increment.current);
  };

  const handlePanelChange = (event, newValue) => {
    setActiveIndex(newValue);

    clearInterval(increment.current);
  };

  const handleIncrement = () => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === 3 ? 0 : prevActiveIndex + 1
    );
  };

  React.useEffect(() => {
    increment.current = setInterval(() => {
      handleIncrement();
    }, delayInterval);

    return () => clearInterval(increment.current);
  }, [activeIndex]);

  const getRenderFeature = (index) => {
    switch (index) {
      case 0:
        return descriptions.boardSuite;
      case 1:
        return descriptions.execSuite;
      case 2:
        return descriptions.dataSuite;
      case 3:
        return descriptions.salesSuite;
      default:
        return descriptions.execSuite;
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        textAlign: "center",
        p: 3,
        mt: 3,
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant={isMobile ? "h3" : "h1"}
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 3,
        }}
      >
        Not all Conversations are alike
      </Typography>
      <Typography variant={isMobile ? "h4" : "h3"} sx={{ mb: 7 }}>
        Only Trryst offers you customized suite templates for different usecases
      </Typography>
      {isMobile ? (
        <SuiteIllustrationsAccordion
          StyledBox={StyledBox}
          activeIndex={activeIndex}
          handleChange={handleAccordionChange}
          suiteType={getRenderFeature(activeIndex).suiteType}
          backgroundPicture={getBackgroundImage(
            getRenderFeature(activeIndex).suiteType
          )}
          description={getRenderFeature(activeIndex)}
        />
      ) : (
        <Grid
          container
          spacing={2}
          direction={{ xs: "column-reverse", md: "row" }}
          alignItems="stretch"
        >
          <Grid
            item
            xs={"auto"}
            sx={{
              minHeight: 200,
              pr: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              bgcolor: "background.paper",
            }}
          >
            <StyledVerticalTabs
              value={activeIndex}
              onChange={handlePanelChange}
              aria-label="icon position tabs example"
              textColor="secondary"
              orientation="vertical"
              centered={true}
              indicatorStyle={getRenderFeature(activeIndex).suiteType}
            >
              <StyledVerticalTab
                label={<Typography variant="h5">Boardroom Suite</Typography>}
                value={0}
                sx={{
                  "&.Mui-selected": {
                    color: (theme) =>
                      theme.palette[getRenderFeature(activeIndex).suiteType]
                        .main,
                  },
                }}
              />
              <StyledVerticalTab
                label={<Typography variant="h5">Executive Suite</Typography>}
                value={1}
                sx={{
                  "&.Mui-selected": {
                    color: (theme) =>
                      theme.palette[getRenderFeature(activeIndex).suiteType]
                        .main,
                  },
                }}
              />
              <StyledVerticalTab
                label={
                  <Typography variant="h5">Dataroom (VDR) Suite</Typography>
                }
                value={2}
                sx={{
                  "&.Mui-selected": {
                    color: (theme) =>
                      theme.palette[getRenderFeature(activeIndex).suiteType]
                        .main,
                  },
                }}
              />
              <StyledVerticalTab
                label={<Typography variant="h5">Sales Suite</Typography>}
                value={3}
                sx={{
                  "&.Mui-selected": {
                    color: (theme) =>
                      theme.palette[getRenderFeature(activeIndex).suiteType]
                        .main,
                  },
                }}
              />
            </StyledVerticalTabs>
          </Grid>
          <Grid
            item
            xs
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              minHeight: "55vh",
            }}
          >
            <StyledBox
              suiteType={getRenderFeature(activeIndex).suiteType}
              sx={{ px: { sm: 3, md: 7, lg: 9 } }}
            >
              <RenderBulletedHeadlineList
                title={getRenderFeature(activeIndex).title}
                listItems={getRenderFeature(activeIndex).description}
                ListItemProps={{ sx: { color: "primary.contrastText" } }}
                ListSubheaderTypographyProps={{ color: "primary.contrastText" }}
              />
            </StyledBox>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
