import React from "react";
import { Box } from "@mui/material";
import { FlowBox } from "blocks/atoms/uistyles";
import dynamic from "next/dynamic";

export default function ShowLandingVideo({ ratio = 0.5 }) {
  const [playerReady, setPlayerReady] = React.useState(false);
  const ReactPlayer = dynamic(() => import("react-player/lazy"), {
    ssr: false,
  });

  return (
    <Box
      sx={{
        position: "relative",
        width: `${ratio * 100}%`,
        zIndex: 5,
        alignSelf: "right",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: `${(ratio * 9) / 16}%`, // 4:3 . half of 75%
      }}
    >
      <ReactPlayer
        playsinline
        loop
        playing
        width="100%"
        height="100%"
        muted
        onReady={() => setPlayerReady(true)}
        url={
          "https://stream.mux.com/BY02G8BjHEgbLwwaBexniiZrFtJdKYcpiWVRukT01MsM00.m3u8"
        }
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </Box>
  );
}
