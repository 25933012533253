//write a mui react component that shows customer logos in a row
import React from "react";
import {
  Typography,
  Box,
  Stack,
  Container,
  styled,
  alpha,
} from "@mui/material";
import Image from "next/image";
import { WavePanel } from "../foundations";
import { ImageColorOnHover } from "blocks/atoms";
import { useIsMobileBreakpoint } from "hooks";
import { motion } from "framer-motion";
import {
  MotionContainer,
  varWrapEnter,
  varFade,
} from "blocks/atoms/uieffects/animate";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  borderRadius: 3 * theme.shape.borderRadius,

  "&:hover": {
    transition: "background-color 1000ms linear",
    backgroundColor: alpha("#fff", 0.7),
  },
}));
export default function ProofPoints({ baseColor = "primary" }) {
  const isMobile = useIsMobileBreakpoint();
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          bgcolor: baseColor === "primary" ? "primary.main" : "canvas.main",
          pb: 8,
          pt: 5,
          mt: -1,
          zIndex: 2,
          px: 2,
        }}
      >
        <Container maxWidth="lg" sx={{ textAlign: "center", p: 3 }}>
          <Typography
            variant="h4"
            color={
              baseColor === "primary" ? "primary.contrastText" : "text.primary"
            }
          >
            Built with the most secure technologies
          </Typography>
          {!isMobile ? (
            <Stack
              spacing={4}
              direction="row"
              sx={{ mt: 4 }}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <StyledBox sx={{ p: 2 }}>
                <ImageColorOnHover
                  src="/static/mock-images/companylogos/OpenAILogo.svg"
                  height={30}
                  mode={baseColor === "primary" ? "normal" : "reverse"}
                />
              </StyledBox>
              <StyledBox sx={{ p: 2 }}>
                <ImageColorOnHover
                  src="/static/mock-images/companylogos/MongoDBLogo.svg"
                  height={50}
                  mode={baseColor === "primary" ? "normal" : "reverse"}
                />
              </StyledBox>
              <StyledBox sx={{ p: 2 }}>
                <ImageColorOnHover
                  src="/static/mock-images/companylogos/LogoAuth0.svg"
                  height={40}
                  mode={baseColor === "primary" ? "normal" : "reverse"}
                />
              </StyledBox>
              <StyledBox sx={{ p: 2 }}>
                <ImageColorOnHover
                  src="/static/mock-images/companylogos/AWSLogo.svg"
                  height={40}
                  mode={baseColor === "primary" ? "normal" : "reverse"}
                />
              </StyledBox>
              <StyledBox sx={{ p: 2, maxWidth: 150 }}>
                <ImageColorOnHover
                  src="/static/mock-images/companylogos/PubnubLogo.svg"
                  height={30}
                  mode={baseColor === "primary" ? "normal" : "reverse"}
                />
              </StyledBox>
            </Stack>
          ) : (
            <>
              <Stack
                spacing={4}
                direction="row"
                sx={{ mt: 4 }}
                justifyContent="space-evenly"
                alignItems="center"
              >
                <StyledBox
                  sx={{
                    bgcolor: "transparent",
                    p: 3,
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    height: 25,
                    width: 120,
                  }}
                >
                  <Image
                    alt={"Open AI"}
                    fill
                    object-fit="contain"
                    src={"/static/mock-images/companylogos/OpenAILogo.svg"}
                    style={{ objectFit: "contain" }}
                  />
                </StyledBox>
                <StyledBox
                  sx={{
                    bgcolor: "transparent",
                    p: 2,
                    width: 150,
                    height: 35,
                    position: "relative",
                  }}
                >
                  <Image
                    alt={"MongoDB"}
                    fill
                    object-fit="contain"
                    src={"/static/mock-images/companylogos/MongoDBLogo.svg"}
                  />
                </StyledBox>
              </Stack>
              <Stack
                spacing={4}
                direction="row"
                sx={{ mt: 4 }}
                justifyContent="space-evenly"
                alignItems="center"
              >
                <StyledBox
                  sx={{
                    bgcolor: "transparent",
                    p: 2,
                    width: 150,
                    height: 35,
                    position: "relative",
                  }}
                >
                  <Image
                    alt={"Auth0"}
                    fill
                    object-fit="contain"
                    src={"/static/mock-images/companylogos/LogoAuth0.svg"}
                  />
                </StyledBox>
                <StyledBox
                  sx={{
                    bgcolor: "transparent",
                    p: 2,
                    width: 150,
                    height: 35,
                    position: "relative",
                  }}
                >
                  <Image
                    alt={"AWS"}
                    fill
                    object-fit="contain"
                    src={"/static/mock-images/companylogos/AWSLogo.svg"}
                  />
                </StyledBox>
                <StyledBox
                  sx={{
                    bgcolor: "transparent",
                    p: 3,
                    width: 150,
                    height: 35,
                    position: "relative",
                  }}
                >
                  <Image
                    alt={"Pubnub"}
                    fill
                    object-fit="contain"
                    src={"/static/mock-images/companylogos/PubnubLogo.svg"}
                  />
                </StyledBox>
              </Stack>
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
}
