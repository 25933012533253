import React from "react";
import { Box, Container, Typography, Tabs, Tab, Grid } from "@mui/material";
import Image from "next/image";
import CameraIndoorRoundedIcon from "@mui/icons-material/CameraIndoorRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import DocumentScannerRoundedIcon from "@mui/icons-material/DocumentScannerRounded";
import { isMobile } from "react-device-detect";
import {
  StyledRoundedTabs,
  StyledRoundedTab,
  StyledRoundedTabList,
} from "blocks/atoms/uistyles";
import TrrystHighlightsPanelBackground from "public/static/customillustrations/explainer-panel-background-pattern.svg";

const meetingInsightHighlight = {
  description:
    "Dont lose track of what you have discussed in your meetings with Trryst’s intelligent transcribe and search feature. Generate magic using powerful AI to generate your meeting minutes automatically.",
  imageSrc: "/static/images/TranscriptScreenshot.png",
};

const smartAnalyticHighlight = {
  description:
    "Smart Analytics from Trryst gives you rich data and smart suggestions that will help you make better and more informed decisions.",
  imageSrc: "/static/images/TaskSuggestionsScreenshot.png",
};

const intelligentSearchHighlight = {
  description:
    "You don't have to scan through hours of your recorded videos to retrieve the section that you want to have a quick relook. You can now just search for it with keywords and we will fetch the relevant snippet for you with our AI-powered search technology.",
  imageSrc: "/static/images/VideoSearchScreenshot.png",
};

export default function HighlightsPanel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const increment = React.useRef(null);

  const delayInterval = 20000;

  const handleChange = (event, newValue) => {
    setActiveIndex(newValue);
    clearInterval(increment.current);
  };

  const handleIncrement = () => {
    setActiveIndex((prevActiveIndex) =>
      prevActiveIndex === 2 ? 0 : prevActiveIndex + 1
    );
  };

  React.useEffect(() => {
    increment.current = setInterval(() => {
      handleIncrement();
    }, delayInterval);

    return () => clearInterval(increment.current);
  }, [activeIndex]);

  const getRenderFeature = (index) => {
    switch (index) {
      case 0:
        return meetingInsightHighlight;
      case 1:
        return smartAnalyticHighlight;
      case 2:
        return intelligentSearchHighlight;

      default:
        return meetingInsightHighlight;
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        backgroundImage: `url(${TrrystHighlightsPanelBackground})`,
        backgroundSize: "cover",

        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="md"
        sx={{
          textAlign: "center",
          p: 3,
          pt: 7,

          minHeight: "85vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={isMobile ? "h3" : "h1"}
          color="common.white"
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          AI - powered Insights enabling effective Outcomes
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            minHeight: { xs: 200, sm: 400, md: 400 },
          }}
        >
          <Image
            alt={"Image"}
            src={getRenderFeature(activeIndex).imageSrc}
            fill
            sizes="100vw"
            style={{
              objectFit: "contain",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 7,
          }}
        >
          <StyledRoundedTabs
            value={activeIndex}
            onChange={handleChange}
            selectionFollowsFocus
            sx={{
              maxWidth: { xs: 600, md: 800 },
            }}
          >
            <StyledRoundedTabList sx={{ backgroundColor: "background.paper" }}>
              <StyledRoundedTab
                value={0}
                textColor="secondary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {!isMobile && (
                  <Image
                    alt={"Meeting Insights"}
                    height={30}
                    width={30}
                    src={
                      activeIndex === 0
                        ? "/static/customicons/meeting-insights-basic-secondary.svg"
                        : "/static/customicons/meeting-insights-basic-primary.svg"
                    }
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                )}
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{
                    ml: isMobile ? 0 : 2,
                    width: "25vw",
                    maxWidth: { xs: 90, sm: 150 },
                  }}
                >
                  Meeting Insights
                </Typography>
              </StyledRoundedTab>

              <StyledRoundedTab
                value={1}
                textColor="secondary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {!isMobile && (
                  <Image
                    alt={"Smart Analytics"}
                    height={30}
                    width={30}
                    src={
                      activeIndex === 1
                        ? "/static/customicons/smart-analytics-basic-secondary.svg"
                        : "/static/customicons/smart-analytics-basic-primary.svg"
                    }
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                )}
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{
                    ml: isMobile ? 0 : 2,
                    width: "25vw",
                    maxWidth: { xs: 90, sm: 150 },
                  }}
                >
                  Smart Analytics
                </Typography>
              </StyledRoundedTab>
              <StyledRoundedTab
                value={2}
                textColor="secondary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {!isMobile && (
                  <Image
                    alt={"Intelligent video search"}
                    height={30}
                    width={30}
                    src={
                      activeIndex === 2
                        ? "/static/customicons/intelligent-video-search-basic-secondary.svg"
                        : "/static/customicons/intelligent-video-search-basic-primary.svg"
                    }
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                )}
                <Typography
                  variant={isMobile ? "subtitle2" : "subtitle1"}
                  sx={{
                    ml: isMobile ? 0 : 2,
                    width: "25vw",
                    maxWidth: { xs: 90, sm: 150 },
                  }}
                >
                  Intelligent Search
                </Typography>
              </StyledRoundedTab>
            </StyledRoundedTabList>
          </StyledRoundedTabs>
        </Box>

        <Typography
          variant={isMobile ? "subtitle1" : "h6"}
          color="primary.contrastText"
          sx={{
            mt: 5,
            height: 300,
            maxWidth: 450,
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          {getRenderFeature(activeIndex).description}
        </Typography>
      </Container>
    </Box>
  );
}
