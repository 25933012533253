// !--- Trryst Confidential. Please do not share or distribute without approval from Trryst (CSuite Ltd.)
import React from "react";
// material
import { styled } from "@mui/material/styles";
// components
import Page from "blocks/views/Layouts/Page";
//
import { BasicAboutIntro } from "blocks/views/LandingPage/foundations";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: "100%",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

const COMMON = {
  scaleX: 0.86,
  skewY: 8,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  opacity: 0,
};

export default function AboutUs() {
  return (
    <RootStyle title="About Us @ Trryst">
      <BasicAboutIntro />
    </RootStyle>
  );
}
